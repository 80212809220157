import Fastdelivery from "components/FastDelivery";

import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";

const Delivery = () => {
  const description =
    "Need quick, accurate translations? Our 24/7 operations and tech-driven workflows allow us to meet your urgent deadlines with precision and speed.";
  const title = `Fast Delivery - Tomedes`;
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        slug="/about/fast-delivery"
      />
      <Fastdelivery />
    </Layout>
  );
};
export default Delivery;
