import React from "react";
import { Virtual, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import { Slides } from "./config";
import Nexticon from "assets/nexticon.svg";
import Pre from "assets/pre.svg";

const ExceptionalQuality = ({ Slides, heading, subheading }) => {
  return (
    <div className="py-12 relative">
      <div className="px-4 pt-5 sm:p-6 max-w-5xl mx-auto text-[#00183B] py-10">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-3xl md:text-4xl font-semibold font-primary leading-[60px] text-center lg:text-left">
              {heading}
            </h1>
            <p className="text-base md:text-lg font-opensans font-normal leading-7 mt-4 text-[#00173A] text-center lg:text-left">
              {subheading}
            </p>
          </div>
          <div className="lg:block hidden w-32 h-24 mt-6 ">
            <Swiper
              modules={[Virtual, Navigation]}
              slidesPerView={4}
              spaceBetween={30}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              // breakpoints={{
              //   1024: {
              //     slidesPerView: 4,
              //   },
              //   767: {
              //     slidesPerView: 2,
              //     spaceBetween: 15,
              //   },
              // }}
              virtual
            >
              <img
                style={{ height: "60px", width: "60px" }}
                src={Nexticon}
                alt="Next"
                className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
              />

              <img
                style={{ height: "60px", width: "60px" }}
                src={Pre}
                alt="Previous"
                className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
              />
            </Swiper>
          </div>
        </div>
      </div>
      <div className=" lg:block hidden 2xl:px-4 pt-5 2xl:p-6 md:py-4 pb-8 2xl:max-w-7xl mx-auto">
        <div className="pl-12 md:pl-16 lg:pl-32 lg:p-0 p-4">
          <Swiper
            modules={[Virtual, Navigation]}
            slidesPerView={4}
            spaceBetween={50}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            virtual
            style={{
              paddingTop: "0px",
              paddingBottom: "80px",
            }}
          >
            {Slides.map((slideContent, index) => (
              <SwiperSlide key={slideContent} virtualIndex={index}>
                <div className="h-[350px] w-[280px]  bg-[#F5F9FF] text-[#00173A] py-8 px-4 flex flex-col gap-6 rounded-lg">
                  <div>
                    <img src={slideContent.imgUrl} alt="" />
                  </div>
                  <div className="h-[50px] pt-2">
                    <h3 className="text-start font-primary font-semibold text-2xl leading-8">
                      {slideContent.heading}
                    </h3>
                  </div>
                  <div className="h-[120px] pt-4">
                    <p className="text-start font-opensans text-base md:text-lg font-normal leading-7">
                      {slideContent.subheading}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="md:block lg:hidden hidden md:py-4 pb-8  mx-auto">
        <div className="pl-12  lg:p-0 p-4">
          <Swiper
            modules={[Virtual, Navigation]}
            slidesPerView={2}
            spaceBetween={30}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            virtual
            style={{
              paddingTop: "0px",
              paddingBottom: "80px",
            }}
          >
            {Slides.map((slideContent, index) => (
              <SwiperSlide key={slideContent} virtualIndex={index}>
                <div className="h-[350px] w-[280px]  bg-[#F5F9FF] text-[#00173A] py-8 px-4 flex flex-col gap-6 rounded-lg">
                  <div>
                    <img src={slideContent.imgUrl} alt="" />
                  </div>
                  <div className="h-[50px] ">
                    <h3 className="text-start font-primary font-semibold text-2xl leading-8">
                      {slideContent.heading}
                    </h3>
                  </div>
                  <div className="h-[120px]">
                    <p className="text-start font-opensans text-base md:text-lg font-normal leading-7">
                      {slideContent.subheading}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}

            <div
              style={{ border: "none", outline: "none" }}
              className="lg:hidden  block absolute bottom-[-2.4rem] w-24 h-24 left-[100px] md:left-[320px] flex gap-5 "
            >
              <img
                style={{ height: "60px", width: "60px" }}
                src={Nexticon}
                alt="Next"
                className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
              />

              <img
                style={{ height: "60px", width: "60px" }}
                src={Pre}
                alt="Previous"
                className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
              />
            </div>
          </Swiper>
        </div>
      </div>
      <div className="sm:block md:hidden lg:hidden  mx-auto">
        <div className="pl-4  lg:p-0">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            modules={[Virtual, Navigation]}
            className="mySwiper"
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            style={{
              paddingTop: "0px",
              paddingBottom: "80px",
            }}
          >
            {Slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="h-[350px] w-[280px] bg-[#F5F9FF] text-[#00173A] py-8 px-4 flex flex-col gap-6 rounded-lg">
                  <div>
                    <img src={slide.imgUrl} alt={slide.heading} />
                  </div>
                  <div className="h-[50px] ">
                    <h3 className="text-start font-primary font-semibold text-2xl leading-8">
                      {slide.heading}
                    </h3>
                  </div>
                  <div className="h-[120px]">
                    <p className="text-start font-opensans text-base md:text-lg font-normal leading-7">
                      {slide.subheading}
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}

            <div className="h-16 flex gap-2 items-center justify-center">
              <div
                style={{ border: "none", outline: "none" }}
                className="absolute bottom-[-1.4rem] w-24 h-24  flex items-center justify-center gap-5 "
              >
                <img
                  style={{ height: "60px", width: "60px" }}
                  src={Nexticon}
                  alt="Next"
                  className=" swiper-button-next    left-[-30px] -translate-y-1/2 cursor-pointer"
                />

                <img
                  style={{ height: "60px", width: "60px" }}
                  src={Pre}
                  alt="Previous"
                  className=" h-[60px] w-[60px] swiper-button-prev   right-[-30px];  -translate-y-1/2 cursor-pointer"
                />
              </div>
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default ExceptionalQuality;
