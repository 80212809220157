import QualityGurantee from "components/YearGuarantee/quality-gurantee";
import React from "react";
import Hero from "assets/heroimg.svg";
import { Link } from "gatsby";
import ExpressTranslation from "./expressTranslation";
import DeliveryMeans from "./deliveryMeans";
import ExceptionalQuality from "./exceptionalQuality";
import NoProblem from "./noProblem";
import ClientTestimonial from "components/YearGuarantee/clientTestimonial";
import { TestimonilaData } from "./config";
import ReliableSupport from "components/Available24/7/reliableSupport";
import { SupportData } from "components/Available24/7/config";
import Arrow from "assets/rightarrow.svg";
import Mask from "assets/mask_group.svg";
import { Slides } from "./config";

const Fastdelivery = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const buttonContent = (
    <button className="border border-[#ffffff] font-opensans px-6 py-2 rounded-sm w-full md:w-max">
      Contact us
    </button>
  );
  const buttonConstant1 = (
    <button className="bg-[#ff7b16] px-6 py-2 font-opensans rounded-sm w-full md:w-max">
      Get Started
    </button>
  );
  const buttonDelivery = (
    <button className=" mt-12 flex  font-semibold text-lg leading-6 font-opensans ">
      Start My Order <img src={Arrow} alt="" className="mt-[2px]" />
    </button>
  );
  const heading = (
    <h1 className="text-3xl md:text-4xl font-primary font-semibold leading-[60px]">
      Get Your Project Done, Right On Time
    </h1>
  );
  return (
    <>
      <section
        className="px-4  py-12 lg:h-[600px] flex items-center"
        style={backgroundImageStyle}
      >
        <QualityGurantee
          heading="Fast Delivery Guaranteed"
          subheading="In a world that demands speed - Tomedes guarantees fast translations to meet your urgent needs. Here, speed and quality go hand in hand.  Our global experts and linguists work 24/7 for top-notch, on-time translations. No delays, just exceptional service."
          button={buttonContent}
          button1={buttonConstant1}
        />
      </section>
      <div className="w-11/12 max-w-7xl mx-auto flex flex-col">
        <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
          <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
            <Link to="/">
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
                alt="home"
              />
            </Link>
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />
            <Link to="/about-tomedes">
              <span>About</span>
            </Link>
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />

            <span>Fast Delivery</span>
          </div>
        </div>
      </div>

      <ExpressTranslation />
      <div className="bg-[#FFEFE2] text-[#00173A] py-16">
        <DeliveryMeans
          heading="What Fast Delivery Means to Us"
          subheading="At Tomedes, 'fast' isn't measured in weeks or months – it's a
              matter of hours or days. Quick, efficient turnaround times are our
              hallmark, ensuring your language projects are delivered precisely
              when you need them."
          text="Every Tomedes service is eligible for expedited delivery, so your
              success is always at your fingertips."
          button={buttonDelivery}
          link="/quote"
          Image={Mask}
        />
      </div>
      <ExceptionalQuality
        heading="Exceptional Quality, Speedy Turnaround"
        subheading=""
        Slides={Slides}
      />
      <NoProblem />
      {/* <ClientTestimonial testimoniladata={TestimonilaData} /> */}
      <ClientTestimonial
        text="Client Testimonials"
        description=" Discover what our clients have to say about the reliability and
              responsiveness of our customer support. Their stories speak to the
              immense value of having a dedicated support system."
        btntext="Read our customer testimonials"
        testimoniladata={TestimonilaData}
        bgClass="bg-white"
      />
      <div className="bg-[#00173A] text-white">
        <ReliableSupport
          // heading="Get Your Project Done,Right On Time"
          heading={heading}
          subheading="Ready to experience faster language solutions? Share your project with us and request a quick quote. Or, if you’d like, we can get started now. Whatever you need, we’ll make it happen fast! Simply fill out the form and tell us what you need. "
          supdata={SupportData}
        />
      </div>
    </>
  );
};
export default Fastdelivery;
