import React, { useState, useEffect } from "react";
import Arrow from "assets/ci_arrow-right.svg";
import Dennis from "assets/dennis.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import Urgent from "assets/urgent.gif";

const NoProblem = () => {
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="bg-[#00173A] text-white py-16">
      <div className=" px-4 pt-5 sm:p-6 md:py-24 pb-8">
        <div className="max-w-5xl mx-auto p-4">
          <div className="grid lg:grid-cols-2 gap-32">
            <div>
              <h1 className="text-3xl md:text-4xl font-primary font-semibold leading-[60px]">
                Urgent Projects, No Problem
              </h1>
              <p className="mt-8 text-base md:text-lg font-normal font-opensans leading-6">
                Stuck with a tight deadline? Tomedes excels at handling rush
                projects. For a small premium, we ensure on-time delivery,
                always at a fair market price. With 24/7 support, we manage
                last-minute requests without compromising quality.
                <br /> <br />
                Our extensive experience guarantees exceptional service. So if
                you’re ready to breathe a sigh of relief, we’re here to help.
              </p>
              <a href="javascript:void(Tawk_API.toggle())">
                <button className="mt-12 flex text-[#FF7B16] text-semibold text-lg leading-6 font-opensans">
                  Talk to Us <img src={Arrow} alt="" />
                </button>
              </a>
            </div>
            <div className=" ">
              <img
                src={Urgent}
                alt="Example GIF"
                className="rounded-lg"
                loading="lazy"
              />
              {/* <div
                className="w-full flex items-center justify-end "
                data-aos="fade-up"
                data-aos-delay="000"
              >
                <div className="bg-[#2A7AF3] w-[320px]  p-3 rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl">
                  <p className="text-left">Is anyone available to chat?</p>
                  <p className="text-right mt-1">Today {currentTime}</p>
                </div>
              </div>
              <div
                className=" w-full flex items-center justify-start mt-6 "
                data-aos="fade-up"
                data-aos-delay="800"
              >
                <div className="flex gap-2 ">
                  <div className="">
                    <img
                      src={Dennis}
                      alt="dennish"
                      className="rounded-3xl mt-12"
                    />
                  </div>
                  <div>
                    <p className="text-sm font-opensans leading-5 font-semibold text-white">
                      Dennis | Tomedes Support
                    </p>
                    <div className="bg-[#0E2A56] w-[320px]  p-3 rounded-tl-2xl rounded-br-2xl rounded-tr-2xl">
                      <p className="text-left text-[#B8D4FF]">
                        Hello there! How may I assist you today?
                      </p>
                      <p className="text-right mt-1 text-[#B8D4FF]">
                        Today {currentTime}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-full flex items-center justify-end "
                data-aos="fade-up"
                data-aos-delay="1200"
              >
                <div className="bg-[#2A7AF3] w-[320px]  p-3 rounded-tl-2xl rounded-bl-2xl rounded-tr-2xl mt-6">
                  <p className="text-left">
                    Could you assist me with a rushed translation for my mobile
                    application?
                  </p>
                  <p className="text-sm font-bold text-white font-opensans leading-5">
                    I need it ASAP.
                  </p>
                  <p className="text-right mt-1">Today {currentTime}</p>
                </div>
              </div>
              <div
                className=" w-full flex items-center justify-start mt-6 "
                data-aos="fade-up"
                data-aos-delay="1600"
              >
                <div className="flex gap-2 ">
                  <div className="">
                    <img
                      src={Dennis}
                      alt="dennish"
                      className="rounded-3xl mt-16"
                    />
                  </div>
                  <div>
                    <p className="text-sm font-opensans leading-5 font-semibold text-white">
                      Dennis | Tomedes Support
                    </p>
                    <div className="bg-[#0E2A56] w-[320px]  p-3 rounded-tl-2xl rounded-br-2xl rounded-tr-2xl">
                      <p className="text-left text-[#B8D4FF]">
                        Sure! we can do the rush translation ASAP. Please share
                        the project details, and we'll get started right away.
                      </p>
                      <p className="text-right mt-1 text-[#B8D4FF]">
                        Today {currentTime}
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoProblem;
