import React from "react";
import { ExpresstranslationData } from "./config";

const ExpressTranslation = () => {
  return (
    <div>
      <div className=" px-4 pt-5 sm:p-6 md:py-24 pb-8">
        <div className="max-w-5xl mx-auto p-4 text-[#00173A]">
          <h1 className="font-primary text-3xl md:text-4xl font-semibold leading-[60px] lg:pr-60 text-center lg:text-left ">
            Experience the Tomedes Difference Express Translations
          </h1>
          <div className="mt-8 grid lg:grid-cols-3 gap-10 ">
            {ExpresstranslationData.map((items, i) => (
              <div className="flex flex-col gap-6">
                <div className="h-[36px]">
                  <img src={items.imgUrl} alt="" />
                </div>

                <h2 className="text-xl font-normal font-primary leading-8 ">
                  {items.heading}
                </h2>
                <p className="text-base md:text-lg font-normal leading-7 font-opensans">
                  {items.subheading}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpressTranslation;
