import Travel from "assets/travel.svg";
import Action from "assets/action.svg";
import Priority from "assets/priority.svg";
import Expert from "assets/expert.svg";
import Technoloy from "assets/technoloy.svg";
import Process from "assets/process.svg";
import Training from "assets/training.svg";
import Governer from "assets/governer.svg";
import Abandoned from "assets/abandoned.svg";
import Kc from "assets/kc.svg";
import Jg from "assets/jg.svg";
import Jm from "assets/jm.svg";
import Collebrate from "assets/collebrate.svg";
export const ExpresstranslationData = [
  {
    imgUrl: Travel,
    heading: "Faster and Precise",
    subheading:
      "At Tomedes, we redefine speed. We deliver swift, pinpoint-precise translations, ensuring you never miss a business opportunity.",
  },
  {
    imgUrl: Action,
    heading: "Efficiency in Action",
    subheading:
      "Our global team works tirelessly to provide on-time translations, preserving quality, even on tight schedules.",
  },
  {
    imgUrl: Priority,
    heading: "Your Business, Our Priority",
    subheading:
      "Expedite orders for speed at a fair premium, always putting your business needs first. No one does fast translations like we do.",
  },
];

export const Slides = [
  {
    imgUrl: Expert,
    heading: "Expert \n Linguists",
    subheading:
      "Our linguists are masters of language and industry-specific experts, ensuring that your translations are linguistically accurate and contextually apt.",
  },
  {
    imgUrl: Technoloy,
    heading: "Advanced Technology",
    subheading:
      "We utilize cutting-edge translation tools and software, ensuring consistency and accuracy, even when working against the clock.",
  },
  {
    imgUrl: Process,
    heading: "Quality Assurance Process",
    subheading:
      "All projects undergo rigorous checks. Our review system weeds out any errors, so we consistently deliver top-tier quality work on schedule.",
  },
  {
    imgUrl: Training,
    heading: "Continuous Training",
    subheading:
      "Our team is always evolving with regular training sessions, keeping us up-to-date with the latest in language nuances and industry terminologies.",
  },
  {
    imgUrl: Collebrate,
    heading: "Collaborative Work Environment",
    subheading:
      "We foster collaboration. Our linguists work together, sharing insights and expertise, ensuring the translation is both quick and accurate.",
  },
  {
    imgUrl: Collebrate,
    heading: "Client Feedback Loop",
    subheading:
      "By incorporating your insights and preferences, we refine our processes, ensuring even faster and more accurate translations in the future.",
  },
];
export const TestimonilaData = [
  {
    imgUrl: Governer,
    title: "THE GOVERNOR’S ACADEMY",
    description:
      "Tomedes is an incredible service partner for my office. I am an executive assistant, and had to translate several documents before my supervisors traveled abroad. Their translation services are fast,      fairly priced, and accurate. I have had them translate into several different languages and they were all great. I will continue to use them for anything we need translated!",
    image: Kc,
    heading: "Katherine Cornetta ",
    subheading: "Administrative Coordinator",
  },
  {
    imgUrl: "",
    title: "Mojave Desert Land Trust",
    description:
      "So happy to have found Tomedes! The nonprofit I work for has used their translation services many times, and I'm always surprised by their attentiveness and quick turnaround. Highly recommend!",
    image: Jg,
    heading: "Jessica Graybill",
    subheading: "Social Media Manager",
  },
  {
    imgUrl: Abandoned,
    title: "",
    description:
      "It has been a delight to work with Tomedes Translation! Extremely professional, responsive and supportive throughout the whole process. They translated a medical manual into Arabic with concrete deadlines, they were all meet and beyond expectation. They walked me through the whole process step by step with high levels of communication and personal follow up at every step of the way.",
    image: Jm,
    heading: "Jeff McKinley",
    subheading: "Co-Director",
  },
];
