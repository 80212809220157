import { Link } from "gatsby";
import React from "react";

const DeliveryMeans = ({ heading, subheading, text, button, Image, link }) => {
  return (
    <div className="  px-4 pt-5 sm:p-6 md:py-24 pb-8">
      <div className="max-w-5xl mx-auto p-4 ">
        <div className="grid lg:grid-cols-2 gap-32">
          <div>
            <h1 className=" text-3xl md:text-4xl font-primary font-bold leading-[60px] ">
              {heading}
            </h1>

            <p className=" mt-8 text-base  md:text-lg font-normal font-opensans  leading-6">
              {subheading}
            </p>
            <p className=" mt-8 text-base md:text-lg font-normal font-opensans leading-6">
              {text}
            </p>
            <Link to={link}>{button}</Link>
          </div>
          <div>
            <div className="flex items-center justify-center">
              <img src={Image} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeliveryMeans;
